<template>
    <v-navigation-drawer
        color="white" class="elevation-12 orderbar"
        v-model="navigation.left" absolute
        clipped hide-overlay permanent floating
        :width="barwidth"
    >
        <order-filter/>
        <order-table/>
    </v-navigation-drawer>
</template>

<script>
import OrderFilter from "../components/OrderFilter";
import OrderTable from "../components/OrderTable";


export default {
    name: "NavBarLeft",
    components: {OrderTable, OrderFilter},
    data: () => ({
        items: [],
        hover: false,

    }),
    created() {
        this.items = [
            {title: 'Order list', icon: 'list', route: 'OrderList'},
            {title: 'Map', icon: 'navigation', route: 'Map'},
        ]
    },
    mounted() {

    },
    computed: {
        navigation() {
            return this.$store.getters.navigation
        },
        barwidth() {
            if (this.$store.getters.settings.barwidth == 1)
                return this.$store.getters.settings.orderbarmin || 400;
            else {
                return this.$store.getters.settings.orderbarmax || 800;
            }
        },

    },
    methods: {
        navigationToggle(arg) {
            this.$store.dispatch('navigationToggle', {commit: 'navigation', data: arg})
        },
        navigate(name) {
            this.$router.push({name: name})
        }
    }
}
</script>

<style lang="scss">
.orderbar {
    top: 64px !important;
    left: 0px !important;
    height: calc(100vh - 97px) !important;
    @media only screen and (max-width: 960px) {
        top: 57px !important;
        height: calc(100vh - 90px) !important;
    }
}
</style>